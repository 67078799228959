// export const BaseURL = 'https://python-llm.onrender.com';
export const BaseURL = 'https://083a-35-91-124-83.ngrok-free.app';
// export const BaseURL = 'http://localhost:2000';

export const Ask = `${BaseURL}/ask`;
export const Upload = `${BaseURL}/upload`;
export const GetFileContent = `${BaseURL}/get-file-content?filename=`;
export const Demograpics = `${BaseURL}/header_attributes_response`;
export const Nlp = `${BaseURL}/deduplicate`;
export const Summarize = `${BaseURL}/summarize`;
export const Nlpschema = `${BaseURL}/get_nlp_schema`;
export const chronological_order = `${BaseURL}/chronological_order`;
export const Nlpres =[
    {
        "attributes": {
            "confidence": "1",
            "date": "01-01-2022",
            "polarity": "positive",
            "relTime": "current status",
            "secondary": "False",
            "status": "uncontrolled",
            "umlsConcept": [
                {
                    "code": "E55.9",
                    "codingScheme": "ICD10CM",
                    "cui": "C0238823",
                    "preferredText": "Vitamin D deficiency, unspecified",
                    "tui": "T049"
                }
            ]
        },
        "extendedSentence": [
            0,
            24
        ],
        "name": "DiseaseDisorderMention",
        "sectionName": [
            "Vitamins and Minerals",
            0,
            24
        ],
        "sectionOffset": [
            0,
            24
        ],
        "sectionOid": "2.16.840.1.113883.3.88.11.62.1.1",
        "sentence": [
            0,
            24
        ],
        "text": [
            "Vitamin D deficiency",
            0,
            24
        ]
    },
    {
        "attributes": {
            "confidence": "1",
            "date": "01-01-2020",
            "polarity": "positive",
            "relTime": "current status",
            "secondary": "False",
            "status": "uncontrolled"
        },
        "extendedSentence": [
            5,
            40
        ],
        "name": "DiseaseDisorderMention",
        "sectionName": [
            "Mental Health",
            0,
            92
        ],
        "sectionOffset": [
            0,
            92
        ],
        "sectionOid": "2.16.840.1.113883.3.88.11.62.1.1",
        "sentence": [
            10,
            35
        ],
        "text": [
            "Recurrent major depression",
            10,
            35
        ],
        "umlsConcept": [
            {
                "code": "F33.9",
                "codingScheme": "ICD10CM",
                "cui": "C0332793",
                "preferredText": "Major depressive disorder, recurrent",
                "tui": "T047"
            }
        ]
    },
    {
        "attributes": {
            "confidence": "1",
            "date": "01-01-2022",
            "polarity": "positive",
            "relTime": "current status",
            "secondary": "False",
            "status": "uncontrolled"
        },
        "extendedSentence": [
            5,
            40
        ],
        "name": "DiseaseDisorderMention",
        "sectionName": [
            "Mental Health",
            0,
            92
        ],
        "sectionOffset": [
            0,
            92
        ],
        "sectionOid": "2.16.840.1.113883.3.88.11.62.1.1",
        "sentence": [
            10,
            35
        ],
        "text": [
            "Generalized anxiety disorder",
            10,
            35
        ],
        "umlsConcept": [
            {
                "code": "F41.1",
                "codingScheme": "ICD10CM",
                "cui": "C0524579",
                "preferredText": "Generalized anxiety disorder",
                "tui": "T047"
            }
        ]
    },
    {
        "attributes": {
            "confidence": "1",
            "date": "01-01-2022",
            "polarity": "positive",
            "relTime": "current status",
            "secondary": "False",
            "status": "uncontrolled"
        },
        "extendedSentence": [
            40,
            90
        ],
        "name": "DiseaseDisorderMention",
        "sectionName": "Mental Health Conditions",
        "sectionOffset": [
            0,
            150
        ],
        "sectionOid": "2.16.840.1.113883.3.88.11.62.1.1",
        "sentence": [
            50,
            80
        ],
        "text": [
            "Binge eating disorder",
            55,
            75
        ],
        "umlsConcept": [
            {
                "code": "F50.9",
                "codingScheme": "ICD10CM",
                "cui": "C1526634",
                "preferredText": "Binge eating disorder, unspecified",
                "tui": "T047"
            }
        ]
    },
    {
        "attributes": {
            "FamilyMember": "None",
            "confidence": "1",
            "date": "01-01-2020",
            "derivedGeneric": "1",
            "fasting": "False",
            "polarity": "positive",
            "relTime": "current status",
            "secondary": "False",
            "status": "stable",
            "umlsConcept": [
                {
                    "code": "F90.9",
                    "codingScheme": "ICD10CM",
                    "cui": "C0020538",
                    "preferredText": "Attention deficit hyperactivity disorder, unspecified",
                    "tui": "T047"
                }
            ]
        },
        "extendedSentence": [
            5,
            45
        ],
        "name": "DiseaseDisorderMention",
        "sectionName": [
            "Problem List",
            0,
            92
        ],
        "sectionOffset": [
            0,
            92
        ],
        "sectionOid": "2.16.840.1.113883.3.88.11.62.1.20.3.1",
        "sentence": [
            10,
            40
        ],
        "text": [
            "Attention deficit hyperactivity disorder",
            10,
            40
        ]
    },
    {
        "attributes": {
            "confidence": "1",
            "date": "01-01-2020",
            "polarity": "positive",
            "relTime": "current status",
            "secondary": "False",
            "status": "uncontrolled"
        },
        "extendedSentence": [
            15,
            45
        ],
        "name": "DiseaseDisorderMention",
        "sectionName": [
            "Neurological Conditions",
            0,
            25
        ],
        "sectionOffset": [
            0,
            100
        ],
        "sectionOid": "2.16.840.1.113883.3.88.11.62.1.1",
        "sentence": [
            20,
            40
        ],
        "text": [
            "Neuropathy",
            25,
            35
        ],
        "umlsConcept": [
            {
                "code": "G62.9",
                "codingScheme": "ICD10CM",
                "cui": "C0332793",
                "preferredText": "Polyneuropathy, unspecified",
                "tui": "T047"
            }
        ]
    },
    {
        "attributes": {
            "confidence": "1",
            "date": "01-01-2022",
            "polarity": "positive",
            "relTime": "current status",
            "secondary": "False",
            "status": "uncontrolled",
            "umlsConcept": [
                {
                    "code": "G62.9",
                    "codingScheme": "ICD10CM",
                    "cui": "C0332793",
                    "preferredText": "Disorder of peripheral nervous system, unspecified",
                    "tui": "T047"
                }
            ]
        },
        "extendedSentence": [
            110,
            150
        ],
        "name": "DiseaseDisorderMention",
        "sectionName": "Neurological Conditions",
        "sectionOffset": [
            100,
            150
        ],
        "sectionOid": "2.16.840.1.113883.3.88.11.62.1.1",
        "sentence": [
            120,
            140
        ],
        "text": [
            "Peripheral motor neuropathy",
            120,
            140
        ]
    },
    {
        "attributes": {
            "FamilyMember": "",
            "confidence": "1",
            "date": "01-01-2022",
            "derivedGeneric": "1",
            "fasting": "False",
            "labUnit": "",
            "labValue": "",
            "medDosage": "",
            "medForm": "",
            "medFrequencyNumber": "",
            "medFrequencyUnit": "",
            "medRoute": "",
            "medStrengthNum": "",
            "medStrengthUnit": "",
            "polarity": "positive",
            "relTime": "current status",
            "secondary": "False",
            "status": "uncontrolled"
        },
        "extendedSentence": [
            5,
            30
        ],
        "name": "DiseaseDisorderMention",
        "sectionName": "Problem List",
        "sectionOffset": [
            0,
            92
        ],
        "sectionOid": "2.16.840.1.113883.3.88.11.62.1",
        "sentence": [
            10,
            25
        ],
        "text": [
            "Nuclear cataract",
            10,
            25
        ],
        "umlsConcept": [
            {
                "code": "H25.9",
                "codingScheme": "ICD10CM",
                "cui": "C0231345",
                "preferredText": "Unspecified cataract",
                "tui": "T047"
            }
        ]
    },
    {
        "attributes": {
            "confidence": "1",
            "date": "01-01-2022",
            "polarity": "positive",
            "relTime": "current status",
            "secondary": "False",
            "status": "controlled"
        },
        "extendedSentence": [
            0,
            30
        ],
        "name": "DiseaseDisorderMention",
        "sectionName": [
            "Problem List",
            0,
            92
        ],
        "sectionOffset": [
            0,
            92
        ],
        "sectionOid": "2.16.840.1.113883.3.88.11.83.115.22.204.1.1",
        "sentence": [
            0,
            24
        ],
        "text": [
            "Essential hypertension",
            0,
            24
        ],
        "umlsConcept": [
            {
                "code": "I10",
                "codingScheme": "ICD10CM",
                "cui": "C0020538",
                "preferredText": "Essential hypertension",
                "tui": "T047"
            }
        ]
    },
    {
        "attributes": {
            "confidence": "1",
            "date": "01-01-2022",
            "polarity": "positive",
            "relTime": "current status",
            "secondary": "False",
            "status": "uncontrolled",
            "umlsConcept": [
                {
                    "code": "K21.9",
                    "codingScheme": "ICD10CM",
                    "cui": "C0020445",
                    "preferredText": "Gastro-esophageal reflux disease without esophagitis",
                    "tui": "T047"
                }
            ]
        },
        "extendedSentence": [
            5,
            40
        ],
        "name": "DiseaseDisorderMention",
        "sectionName": [
            "Problem List",
            0,
            92
        ],
        "sectionOffset": [
            0,
            92
        ],
        "sectionOid": "2.16.840.1.113883.3.88.11.62.1.20.3.1",
        "sentence": [
            10,
            35
        ],
        "text": [
            "Gastroesophageal reflux disease",
            10,
            35
        ]
    },
    {
        "attributes": {
            "FamilyMember": "None",
            "confidence": "1",
            "date": "01-01-2022",
            "relTime": "current status",
            "secondary": "False",
            "status": "uncontrolled",
            "umlsConcept": [
                {
                    "code": "K40.90",
                    "codingScheme": "ICD10CM",
                    "cui": "C0159334",
                    "preferredText": "Unilateral inguinal hernia without obstruction or gangrene",
                    "tui": "T047"
                }
            ]
        },
        "extendedSentence": [
            5,
            35
        ],
        "name": "DiseaseDisorderMention",
        "sectionName": "Problem List",
        "sectionOffset": [
            0,
            92
        ],
        "sectionOid": "2.16.840.1.113883.3.88.11.62.1.20.2.2.1.1",
        "sentence": [
            10,
            30
        ],
        "text": [
            "Left inguinal hernia",
            15,
            30
        ]
    },
    {
        "attributes": {
            "FamilyMember": "",
            "confidence": "1",
            "date": "01-01-2022",
            "derivedGeneric": "1",
            "fasting": "False",
            "labUnit": "",
            "labValue": "",
            "medDosage": "",
            "medForm": "",
            "medFrequencyNumber": "",
            "medFrequencyUnit": "",
            "medRoute": "",
            "medStrengthNum": "",
            "medStrengthUnit": "",
            "polarity": "positive",
            "relTime": "current status",
            "secondary": "False",
            "status": "stable"
        },
        "extendedSentence": [
            5,
            40
        ],
        "name": "DiseaseDisorderMention",
        "sectionName": [
            "Problem List",
            0,
            92
        ],
        "sectionOffset": [
            0,
            92
        ],
        "sectionOid": "2.16.840.1.113883.3.88.11.62.1",
        "sentence": [
            10,
            35
        ],
        "text": [
            "Paraesophageal hernia",
            10,
            35
        ],
        "umlsConcept": [
            {
                "code": "K44.9",
                "codingScheme": "ICD10CM",
                "cui": "C0151744",
                "preferredText": "Diaphragmatic hernia, unspecified",
                "tui": "T047"
            }
        ]
    },
    {
        "attributes": {
            "FamilyMember": "None",
            "confidence": "1",
            "date": "01-01-2022",
            "relTime": "current status",
            "secondary": "False",
            "status": "chronic",
            "umlsConcept": [
                {
                    "code": "N30.90",
                    "codingScheme": "ICD10CM",
                    "cui": "C0010674",
                    "preferredText": "Chronic cystitis without hematuria",
                    "tui": "T047"
                }
            ]
        },
        "extendedSentence": [
            5,
            40
        ],
        "name": "DiseaseDisorderMention",
        "sectionName": "Problem List",
        "sectionOffset": [
            0,
            92
        ],
        "sectionOid": "2.16.840.1.113883.3.88.11.62.1",
        "sentence": [
            10,
            30
        ],
        "text": [
            "Chronic cystitis",
            15,
            30
        ]
    },
    {
        "attributes": {
            "confidence": "1",
            "date": "01-01-2022",
            "polarity": "positive",
            "relTime": "current status",
            "secondary": "False",
            "status": "stable"
        },
        "extendedSentence": [
            5,
            25
        ],
        "name": "SignSymptomMention",
        "sectionName": [
            "Problem List",
            0,
            92
        ],
        "sectionOffset": [
            0,
            92
        ],
        "sectionOid": "2.16.840.1.113883.3.88.11.62.1.20.2.1",
        "sentence": [
            10,
            20
        ],
        "text": [
            "Foot callus",
            10,
            20
        ],
        "umlsConcept": [
            {
                "code": "L84.0",
                "codingScheme": "ICD10CM",
                "cui": "C0332793",
                "preferredText": "Callus",
                "tui": "T047"
            }
        ]
    },
    {
        "attributes": {
            "FamilyMember": "",
            "confidence": "1",
            "date": "01-01-2022",
            "derivedGeneric": "0",
            "fasting": "False",
            "labUnit": "",
            "labValue": "",
            "medDosage": "",
            "medForm": "",
            "medFrequencyNumber": "",
            "medFrequencyUnit": "",
            "medRoute": "",
            "medStrengthNum": "",
            "medStrengthUnit": "",
            "polarity": "positive",
            "relTime": "current status",
            "secondary": "False",
            "status": "chronic"
        },
        "extendedSentence": [
            5,
            40
        ],
        "name": "DiseaseDisorderMention",
        "sectionName": "Problem List",
        "sectionOffset": [
            0,
            92
        ],
        "sectionOid": "2.16.840.1.113883.3.88.11.83.115",
        "sentence": [
            10,
            35
        ],
        "text": [
            "Chronic low back pain",
            15,
            30
        ],
        "umlsConcept": [
            {
                "code": "M54.5",
                "codingScheme": "ICD10CM",
                "cui": "C0262922",
                "preferredText": "Low back pain",
                "tui": "T047"
            }
        ]
    },
    {
        "attributes": {
            "FamilyMember": "None",
            "confidence": "1",
            "date": "Not specified",
            "relTime": "history status",
            "secondary": "False",
            "status": "resolved",
            "umlsConcept": [
                {
                    "code": "N39.0",
                    "codingScheme": "ICD10CM",
                    "cui": "C0012634",
                    "preferredText": "Urinary tract infection, unspecified",
                    "tui": "T047"
                }
            ]
        },
        "extendedSentence": [
            5,
            40
        ],
        "name": "DiseaseDisorderMention",
        "sectionName": "Problem List",
        "sectionOffset": [
            0,
            92
        ],
        "sectionOid": "2.16.840.1.113883.3.88.11.62.1",
        "sentence": [
            10,
            35
        ],
        "text": [
            "History of urinary tract infection",
            10,
            35
        ]
    },
    {
        "attributes": {
            "confidence": "1",
            "date": "01-01-2022",
            "polarity": "positive",
            "relTime": "current status",
            "secondary": "False",
            "status": "stable"
        },
        "extendedSentence": [
            15,
            50
        ],
        "name": "DiseaseDisorderMention",
        "sectionName": [
            "Musculoskeletal",
            0,
            15
        ],
        "sectionOffset": [
            0,
            15
        ],
        "sectionOid": "2.16.840.1.113883.3.88.11.83.115",
        "sentence": [
            15,
            40
        ],
        "text": [
            "Acquired hammer toe of right foot",
            15,
            40
        ],
        "umlsConcept": [
            {
                "code": "M20.40",
                "codingScheme": "ICD10CM",
                "cui": "C0332793",
                "preferredText": "Acquired hammer toe, unspecified foot",
                "tui": "T047"
            }
        ]
    },
    {
        "attributes": {
            "FamilyMember": "None",
            "confidence": "1",
            "date": "01-01-2020",
            "relTime": "current status",
            "secondary": "False",
            "status": "resolved",
            "umlsConcept": [
                {
                    "code": "S98.012A",
                    "codingScheme": "ICD10CM",
                    "cui": "C0175623",
                    "preferredText": "Partial traumatic amputation of right toe",
                    "tui": "T061"
                }
            ]
        },
        "extendedSentence": [
            40,
            90
        ],
        "name": "ProcedureMention",
        "sectionName": "Surgical History",
        "sectionOffset": [
            0,
            150
        ],
        "sectionOid": "2.16.840.1.113883.3.88.11.62.20",
        "sentence": [
            50,
            80
        ],
        "text": [
            "Partial amputation of right toe",
            50,
            80
        ]
    },
    {
        "attributes": {
            "FamilyMember": "None",
            "confidence": "1",
            "date": "Not specified",
            "relTime": "history status",
            "secondary": "False",
            "status": "resolved",
            "umlsConcept": [
                {
                    "code": "S05.9XXA",
                    "codingScheme": "ICD10CM",
                    "cui": "C0231345",
                    "preferredText": "Injury of eye and orbit, unspecified",
                    "tui": "T033"
                }
            ]
        },
        "extendedSentence": [
            5,
            40
        ],
        "name": "DiseaseDisorderMention",
        "sectionName": [
            "Problem List",
            0,
            92
        ],
        "sectionOffset": [
            0,
            92
        ],
        "sectionOid": "2.16.840.1.113883.3.88.11.62.1.20.2.1",
        "sentence": [
            10,
            35
        ],
        "text": [
            "History of injury of eye region",
            10,
            35
        ]
    },
    {
        "attributes": {
            "confidence": "1",
            "date": "MM-DD-YYYY",
            "polarity": "positive",
            "relTime": "current status",
            "secondary": "False",
            "status": "uncontrolled",
            "umlsConcept": [
                {
                    "code": "M54.5",
                    "codingScheme": "ICD10CM",
                    "cui": "C0220746",
                    "preferredText": "Low back pain",
                    "tui": "T047"
                }
            ]
        },
        "extendedSentence": [
            0,
            92
        ],
        "name": "SignSymptomMention",
        "sectionName": [
            "Musculoskeletal",
            0,
            92
        ],
        "sectionOffset": [
            0,
            92
        ],
        "sectionOid": "2.16.840.1.113883.3.88.11.62.1.1.1.1.1",
        "sentence": [
            0,
            45
        ],
        "text": [
            "Back Problems - lower back pain exacerbated with movement",
            0,
            45
        ]
    },
    {
        "attributes": {
            "FamilyMember": "",
            "confidence": "1",
            "date": "01-01-2022",
            "derivedGeneric": "1",
            "fasting": "False",
            "labUnit": "",
            "labValue": "",
            "medDosage": "",
            "medForm": "",
            "medFrequencyNumber": "",
            "medFrequencyUnit": "",
            "medRoute": "",
            "medStrengthNum": "",
            "medStrengthUnit": "",
            "polarity": "positive",
            "relTime": "current status",
            "secondary": "False",
            "status": "uncontrolled"
        },
        "extendedSentence": [
            5,
            35
        ],
        "name": "DiseaseDisorderMention",
        "sectionName": [
            "Problem List",
            0,
            92
        ],
        "sectionOffset": [
            0,
            92
        ],
        "sectionOid": "2.16.840.1.113883.3.88.11.62.1.1",
        "sentence": [
            10,
            30
        ],
        "text": [
            "Diverticulitis",
            15,
            25
        ],
        "umlsConcept": [
            {
                "code": "K57.90",
                "codingScheme": "ICD10CM",
                "cui": "C0013364",
                "preferredText": "Diverticulitis of intestine, unspecified as acute or chronic, without perforation or abscess",
                "tui": "T047"
            }
        ]
    },
    {
        "attributes": {
            "FamilyMember": "None",
            "confidence": "1",
            "date": "Not specified",
            "derivedGeneric": "0",
            "fasting": "False",
            "labUnit": "Not applicable",
            "labValue": "Not applicable",
            "medDosage": "Not applicable",
            "medForm": "Not applicable",
            "medFrequencyNumber": "Not applicable",
            "medFrequencyUnit": "Not applicable",
            "medRoute": "Not applicable",
            "medStrengthNum": "Not applicable",
            "medStrengthUnit": "Not applicable",
            "polarity": "positive",
            "relTime": "current status",
            "secondary": "False",
            "status": "uncontrolled",
            "umlsConcept": [
                {
                    "code": "I10",
                    "codingScheme": "ICD10CM",
                    "cui": "C0020538",
                    "preferredText": "Essential hypertension",
                    "tui": "T047"
                }
            ]
        },
        "extendedSentence": [
            0,
            45
        ],
        "name": "DiseaseDisorderMention",
        "sectionName": [
            "Problem List",
            0,
            92
        ],
        "sectionOffset": [
            0,
            92
        ],
        "sectionOid": "2.16.840.1.113883.3.88.11.62.1.20.7.1.13.3.1.7",
        "sentence": [
            0,
            35
        ],
        "text": [
            "Hypertension - H/o high blood pressure",
            0,
            35
        ]
    },
    {
        "attributes": {
            "FamilyMember": "None",
            "confidence": "1",
            "date": "Unknown",
            "derivedGeneric": "0",
            "fasting": "False",
            "polarity": "positive",
            "relTime": "history status",
            "secondary": "False",
            "status": "resolved",
            "umlsConcept": [
                {
                    "code": "H66.90",
                    "codingScheme": "ICD10CM",
                    "cui": "C0014074",
                    "preferredText": "Chronic suppurative otitis media, unspecified ear",
                    "tui": "T047"
                }
            ]
        },
        "extendedSentence": [
            0,
            50
        ],
        "name": "DiseaseDisorderMention",
        "sectionName": [
            "Problem List",
            0,
            92
        ],
        "sectionOffset": [
            0,
            92
        ],
        "sectionOid": "2.16.840.1.113883.3.88.11.62.1.1",
        "sentence": [
            0,
            35
        ],
        "text": [
            "Chronic Ear Infections - as a child",
            0,
            35
        ]
    },
    {
        "attributes": {
            "FamilyMember": "None",
            "confidence": "1",
            "date": "Unknown",
            "relTime": "history status",
            "secondary": "False",
            "status": "resolved",
            "umlsConcept": [
                {
                    "code": "B05.9",
                    "codingScheme": "ICD10CM",
                    "cui": "C0020445",
                    "preferredText": "Chickenpox, unspecified",
                    "tui": "T047"
                }
            ]
        },
        "extendedSentence": [
            0,
            50
        ],
        "name": "DiseaseDisorderMention",
        "sectionName": [
            "Problem List",
            0,
            92
        ],
        "sectionOffset": [
            0,
            92
        ],
        "sectionOid": "2.16.840.1.113883.3.88.11.62.1.20.2.2.1",
        "sentence": [
            0,
            35
        ],
        "text": [
            "Chicken Pox - chicken pox as a child",
            0,
            35
        ]
    },
    {
        "attributes": {
            "confidence": "1",
            "date": "MM-DD-YYYY",
            "derivedGeneric": "1",
            "medDosage": "50 mg",
            "medForm": "tablet",
            "medFrequencyNumber": "1",
            "medFrequencyUnit": "day",
            "medRoute": "oral",
            "medStrengthNum": "50",
            "medStrengthUnit": "mg",
            "polarity": "positive",
            "relTime": "current status",
            "secondary": "False",
            "status": "stable"
        },
        "extendedSentence": [
            0,
            92
        ],
        "name": "MedicationMention",
        "sectionName": "Medications",
        "sectionOffset": [
            0,
            92
        ],
        "sectionOid": "2.16.840.1.113883.3.88.11.62.1",
        "sentence": [
            0,
            92
        ],
        "text": [
            "metoprolol_succinate_ER metoprolol succinate ER 50 mg tablet,extended release 24 hr Take 1 tablet every day by oral route for 28 days.",
            0,
            92
        ],
        "umlsConcept": [
            {
                "code": "19815",
                "codingScheme": "RxNorm",
                "cui": "C0776211",
                "preferredText": "metoprolol succinate 50 MG Extended Release Oral Tablet",
                "tui": "T195"
            }
        ]
    },
    {
        "attributes": {
            "confidence": "1",
            "medDosage": "20 mg",
            "medForm": "tablet",
            "medFrequencyNumber": "1",
            "medFrequencyUnit": "day",
            "medRoute": "oral",
            "medStrengthNum": "20",
            "medStrengthUnit": "mg",
            "relTime": "current status",
            "secondary": "False",
            "status": "active"
        },
        "extendedSentence": [
            0,
            92
        ],
        "name": "MedicationMention",
        "sectionName": [
            "Medications",
            0,
            92
        ],
        "sectionOffset": [
            0,
            92
        ],
        "sectionOid": "2.16.840.1.113883.3.88.11.62.1.1",
        "sentence": [
            0,
            92
        ],
        "text": [
            "lisinopril lisinopril 20 mg tablet Take 1 tablet every day by oral route as directed for 28 days.",
            0,
            92
        ],
        "umlsConcept": [
            {
                "code": "311362",
                "codingScheme": "RxNorm",
                "cui": "C152144",
                "preferredText": "Lisinopril 20 MG Oral Tablet",
                "tui": "T195"
            }
        ]
    },
    {
        "attributes": {
            "confidence": "1",
            "date": "MM-DD-YYYY",
            "derivedGeneric": "1",
            "medDosage": "10 mg",
            "medForm": "tablet",
            "medFrequencyNumber": "2",
            "medFrequencyUnit": "times a day",
            "medRoute": "oral",
            "medStrengthNum": "10",
            "medStrengthUnit": "mg",
            "polarity": "positive",
            "relTime": "current status",
            "secondary": "False",
            "status": "new diagnosis"
        },
        "extendedSentence": [
            0,
            92
        ],
        "name": "MedicationMention",
        "sectionName": "Medications",
        "sectionOffset": [
            0,
            92
        ],
        "sectionOid": "2.16.840.1.113883.10.20.22.4.12",
        "sentence": [
            0,
            92
        ],
        "text": [
            "dextroamphetamine-amphetamine dextroamphetamine-amphetamine 10 mg tablet Take 1 tablet twice a day by oral route as directed for 28 days.",
            0,
            92
        ],
        "umlsConcept": [
            {
                "code": "197366",
                "codingScheme": "RxNorm",
                "cui": "C0801136",
                "preferredText": "dextroamphetamine-amphetamine 10 MG Oral Tablet",
                "tui": "T195"
            }
        ]
    },
    {
        "attributes": {
            "confidence": "1",
            "date": "Not specified",
            "medDosage": "10 mg",
            "medForm": "tablet",
            "medFrequencyNumber": "2",
            "medFrequencyUnit": "times a day",
            "medRoute": "oral",
            "medStrengthNum": "10",
            "medStrengthUnit": "mg",
            "relTime": "current status",
            "secondary": "False",
            "status": "Not specified"
        },
        "extendedSentence": [
            0,
            92
        ],
        "name": "MedicationMention",
        "sectionName": "Medications",
        "sectionOffset": [
            0,
            92
        ],
        "sectionOid": "2.16.840.1.113883.3.88.11.83.115",
        "sentence": [
            0,
            92
        ],
        "text": [
            "Adderall 10 mg tablet Take 1 tablet twice a day by oral route as directed for 28 days.",
            0,
            92
        ],
        "umlsConcept": [
            {
                "code": "856559",
                "codingScheme": "RxNorm",
                "cui": "C152144",
                "preferredText": "Adderall 10 MG Oral Tablet",
                "tui": "T195"
            }
        ]
    },
    {
        "attributes": {
            "FamilyMember": "",
            "confidence": "1",
            "date": "MM-DD-YYYY",
            "derivedGeneric": "1",
            "fasting": "False",
            "labUnit": "",
            "labValue": "",
            "medDosage": "800 mg-trimethoprim 160 mg",
            "medForm": "tablet",
            "medFrequencyNumber": "2",
            "medFrequencyUnit": "day",
            "medRoute": "by mouth",
            "medStrengthNum": "800",
            "medStrengthUnit": "mg",
            "polarity": "positive",
            "relTime": "current status",
            "secondary": "False",
            "status": "new diagnosis",
            "umlsConcept": [
                {
                    "code": "198443",
                    "codingScheme": "RxNorm",
                    "cui": "C152144",
                    "preferredText": "Sulfamethoxazole 800 MG / Trimethoprim 160 MG Oral Tablet",
                    "tui": "T195"
                }
            ]
        },
        "extendedSentence": [
            0,
            92
        ],
        "name": "MedicationMention",
        "sectionName": [
            "Medications",
            0,
            92
        ],
        "sectionOffset": [
            0,
            92
        ],
        "sectionOid": "2.16.840.1.113883.3.88.11.62.1.20210501000000.1",
        "sentence": [
            0,
            92
        ],
        "text": [
            "sulfamethoxazole_trimethoprim sulfamethoxazole 800 mg-trimethoprim 160 mg tablet TAKE 1 TABLET BY MOUTH TWICE A DAY FOR 14 DAYS",
            0,
            92
        ]
    },
    {
        "attributes": {
            "confidence": "1",
            "date": "MM-DD-YYYY",
            "derivedGeneric": "1",
            "medDosage": "1 tablet",
            "medForm": "tablet",
            "medFrequencyNumber": "1",
            "medFrequencyUnit": "every 12 hours",
            "medRoute": "oral route",
            "medStrengthNum": "800",
            "medStrengthUnit": "mg",
            "polarity": "positive",
            "relTime": "current status",
            "secondary": "False",
            "status": "new diagnosis",
            "umlsConcept": [
                {
                    "code": "197366",
                    "codingScheme": "RxNorm",
                    "cui": "C152144",
                    "preferredText": "Sulfamethoxazole 800 MG / Trimethoprim 160 MG Oral Tablet",
                    "tui": "T195"
                }
            ]
        },
        "extendedSentence": [
            0,
            100
        ],
        "name": "MedicationMention",
        "sectionName": "Medications",
        "sectionOffset": [
            0,
            100
        ],
        "sectionOid": "2.16.840.1.113883.3.88.11.62.1",
        "sentence": [
            0,
            100
        ],
        "text": [
            "Bactrim_DS Bactrim DS 800 mg-160 mg tablet Take 1 tablet every 12 hours by oral route for 7 days.",
            0,
            100
        ]
    },
    {
        "attributes": {
            "FamilyMember": "",
            "confidence": "1",
            "date": "",
            "derivedGeneric": "0",
            "fasting": "False",
            "labUnit": "",
            "labValue": "",
            "medDosage": "1 tablet",
            "medForm": "tablet",
            "medFrequencyNumber": "4",
            "medFrequencyUnit": "times a day",
            "medRoute": "oral",
            "medStrengthNum": "10",
            "medStrengthUnit": "mg",
            "polarity": "positive",
            "relTime": "current status",
            "secondary": "False",
            "status": ""
        },
        "extendedSentence": [
            0,
            92
        ],
        "name": "MedicationMention",
        "sectionName": [
            "Medications",
            0,
            92
        ],
        "sectionOffset": [
            0,
            92
        ],
        "sectionOid": "2.16.840.1.113883.10.20.22.4.12",
        "sentence": [
            0,
            92
        ],
        "text": [
            "hydrocodone_acetaminophen hydrocodone 10 mg-acetaminophen 325 mg tablet Take 1 tablet 4 times a day by oral route as needed for 14 days.",
            0,
            92
        ],
        "umlsConcept": [
            {
                "code": "197444",
                "codingScheme": "RxNorm",
                "cui": "C0801136",
                "preferredText": "hydrocodone 10 mg-acetaminophen 325 mg oral tablet",
                "tui": "T195"
            }
        ]
    },
    {
        "attributes": {
            "confidence": "1",
            "date": "MM-DD-YYYY",
            "derivedGeneric": "1",
            "medDosage": "650 mg",
            "medForm": "tablet",
            "medFrequencyNumber": "1",
            "medFrequencyUnit": "every 8 hours",
            "medRoute": "by mouth",
            "medStrengthNum": "650",
            "medStrengthUnit": "mg",
            "polarity": "positive",
            "relTime": "current status",
            "secondary": "False",
            "status": "new diagnosis"
        },
        "extendedSentence": [
            0,
            92
        ],
        "name": "MedicationMention",
        "sectionName": "Medications",
        "sectionOffset": [
            0,
            92
        ],
        "sectionOid": "2.16.840.1.113883.3.88.11.62.1",
        "sentence": [
            0,
            92
        ],
        "text": [
            "acetaminophen_ER acetaminophen ER 650 mg tablet,extended release TAKE 1 TABLET BY MOUTH EVERY 8 HOURS AS NEEDED FOR 28 DAYS",
            0,
            92
        ],
        "umlsConcept": [
            {
                "code": "197365",
                "codingScheme": "RxNorm",
                "cui": "C152144",
                "preferredText": "Acetaminophen 650 MG Extended Release Oral Tablet",
                "tui": "T195"
            }
        ]
    },
    {
        "attributes": {
            "confidence": "1",
            "date": "Not specified",
            "medDosage": "650 mg",
            "medForm": "tablet",
            "medFrequencyNumber": "1",
            "medFrequencyUnit": "tablet",
            "medRoute": "oral",
            "medStrengthNum": "650",
            "medStrengthUnit": "mg",
            "relTime": "current status",
            "secondary": "False",
            "status": "Not specified"
        },
        "extendedSentence": [
            0,
            92
        ],
        "name": "MedicationMention",
        "sectionName": "Medications",
        "sectionOffset": [
            0,
            92
        ],
        "sectionOid": "2.16.840.1.113883.3.88.11.62.1",
        "sentence": [
            0,
            92
        ],
        "text": [
            "Tylenol Arthritis Pain 650 mg tablet, extended release Take 1 tablet every 8 hours by oral route as needed for 28 days.",
            0,
            92
        ],
        "umlsConcept": [
            {
                "code": "197366",
                "codingScheme": "RxNorm",
                "cui": "C152144",
                "preferredText": "Acetaminophen 650 MG Extended Release Oral Tablet",
                "tui": "T195"
            }
        ]
    },
    {
        "attributes": {
            "FamilyMember": "",
            "confidence": "1",
            "date": "MM-DD-YYYY",
            "derivedGeneric": "1",
            "fasting": "False",
            "labUnit": "",
            "labValue": "",
            "medDosage": "8%",
            "medForm": "topical solution",
            "medFrequencyNumber": "1",
            "medFrequencyUnit": "daily",
            "medRoute": "topical",
            "medStrengthNum": "8",
            "medStrengthUnit": "%",
            "polarity": "positive",
            "relTime": "current status",
            "secondary": "False",
            "status": "stable",
            "umlsConcept": [
                {
                    "code": "197366",
                    "codingScheme": "RxNorm",
                    "cui": "C152144",
                    "preferredText": "ciclopirox 8 % topical solution",
                    "tui": "T195"
                }
            ]
        },
        "extendedSentence": [
            0,
            92
        ],
        "name": "MedicationMention",
        "sectionName": [
            "Medications",
            0,
            92
        ],
        "sectionOffset": [
            0,
            92
        ],
        "sectionOid": "2.16.840.1.113883.10.20.22.4.12",
        "sentence": [
            0,
            92
        ],
        "text": [
            "ciclopirox ciclopirox 8 % topical solution APPLY TO THE AFFECTED AREA(S) BY TOPICAL ROUTE ONCE DAILY PREFERABLY AT BEDTIME OR 8 HOURS BEFORE WASHING",
            0,
            92
        ]
    },
    {
        "attributes": {
            "confidence": "1",
            "date": "MM-DD-YYYY",
            "derivedGeneric": "1",
            "medDosage": "0.5 mg",
            "medForm": "tablet",
            "medFrequencyNumber": "1",
            "medFrequencyUnit": "day",
            "medRoute": "oral",
            "medStrengthNum": "0.5",
            "medStrengthUnit": "mg",
            "polarity": "positive",
            "relTime": "current status",
            "secondary": "False",
            "status": "stable"
        },
        "extendedSentence": [
            0,
            100
        ],
        "name": "MedicationMention",
        "sectionName": "Medications",
        "sectionOffset": [
            0,
            100
        ],
        "sectionOid": "2.16.840.1.113883.10.20.22.4.12",
        "sentence": [
            0,
            50
        ],
        "text": [
            "lorazepam ['lorazepam 0.5 mg tablet TAKE 1 TABLET EVERY DAY BY ORAL ROUTE AS NEEDED.', 'lorazepam 0.5 mg tablet Take 1 tablet every day by oral route as needed.']",
            0,
            100
        ],
        "umlsConcept": [
            {
                "code": "19844",
                "codingScheme": "RxNorm",
                "cui": "C0749631",
                "preferredText": "lorazepam 0.5 MG Oral Tablet",
                "tui": "T121"
            }
        ]
    },
    {
        "attributes": {
            "FamilyMember": "",
            "confidence": "1",
            "date": "MM-DD-YYYY",
            "derivedGeneric": "1",
            "fasting": "False",
            "labUnit": "",
            "labValue": "",
            "medDosage": "500 mg",
            "medForm": "capsule",
            "medFrequencyNumber": "4",
            "medFrequencyUnit": "times a day",
            "medRoute": "by mouth",
            "medStrengthNum": "500",
            "medStrengthUnit": "mg",
            "polarity": "positive",
            "relTime": "current status",
            "secondary": "False",
            "status": "prescribed",
            "umlsConcept": [
                {
                    "code": "197366",
                    "codingScheme": "RxNorm",
                    "cui": "C152144",
                    "preferredText": "cephalexin 500 MG Oral Capsule",
                    "tui": "T195"
                }
            ]
        },
        "extendedSentence": [
            0,
            100
        ],
        "name": "MedicationMention",
        "sectionName": "Medications",
        "sectionOffset": [
            0,
            100
        ],
        "sectionOid": "2.16.840.1.113883.10.20.22.4.12",
        "sentence": [
            0,
            100
        ],
        "text": [
            "cephalexin ['cephalexin 500 mg capsule TAKE 1 CAPSULE BY MOUTH FOUR TIMES A DAY FOR 14 DAYS', 'cephalexin 500 mg capsule Take 1 capsule 4 times a day by oral route as directed for 10 days.']",
            0,
            100
        ]
    },
    {
        "attributes": {
            "confidence": "1",
            "date": "Not specified",
            "medDosage": "40 mg",
            "medForm": "tablet",
            "medFrequencyNumber": "1",
            "medFrequencyUnit": "day",
            "medRoute": "oral",
            "medStrengthNum": "40",
            "medStrengthUnit": "mg",
            "relTime": "current status",
            "secondary": "False",
            "status": "Not specified"
        },
        "extendedSentence": [
            0,
            92
        ],
        "name": "MedicationMention",
        "sectionName": [
            "Medications",
            0,
            92
        ],
        "sectionOffset": [
            0,
            92
        ],
        "sectionOid": "2.16.840.1.113883.10.20.22.4.12",
        "sentence": [
            0,
            92
        ],
        "text": [
            "pantoprazole pantoprazole 40 mg tablet,delayed release Take 1 tablet every day by oral route in the morning for 28 days.",
            0,
            92
        ],
        "umlsConcept": [
            {
                "code": "411673",
                "codingScheme": "RxNorm",
                "cui": "C152144",
                "preferredText": "pantoprazole 40 MG Delayed Release Oral Tablet",
                "tui": "T195"
            }
        ]
    },
    {
        "attributes": {
            "FamilyMember": "",
            "confidence": "1",
            "date": "MM-DD-YYYY",
            "derivedGeneric": "1",
            "fasting": "False",
            "labUnit": "",
            "labValue": "",
            "medDosage": "20 mg",
            "medForm": "tablet",
            "medFrequencyNumber": "1",
            "medFrequencyUnit": "day",
            "medRoute": "oral",
            "medStrengthNum": "20",
            "medStrengthUnit": "mg",
            "polarity": "positive",
            "relTime": "current status",
            "secondary": "False",
            "status": "stable",
            "umlsConcept": [
                {
                    "code": "198443",
                    "codingScheme": "RxNorm",
                    "cui": "C152144",
                    "preferredText": "furosemide 20 MG Oral Tablet",
                    "tui": "T195"
                }
            ]
        },
        "extendedSentence": [
            0,
            92
        ],
        "name": "MedicationMention",
        "sectionName": [
            "Medications",
            0,
            92
        ],
        "sectionOffset": [
            0,
            92
        ],
        "sectionOid": "2.16.840.1.113883.10.20.22.4.12",
        "sentence": [
            0,
            92
        ],
        "text": [
            "furosemide furosemide 20 mg tablet Take 1 tablet every day by oral route in the morning for 28 days.",
            0,
            92
        ]
    },
    {
        "attributes": {
            "confidence": "1",
            "date": "MM-DD-YYYY",
            "derivedGeneric": "1",
            "medDosage": "10 ML",
            "medForm": "mouthwash",
            "medFrequencyNumber": "2",
            "medFrequencyUnit": "DAILY",
            "medRoute": "ORAL",
            "medStrengthNum": "0.12",
            "medStrengthUnit": "%",
            "polarity": "positive",
            "relTime": "current status",
            "secondary": "False",
            "status": "controlled"
        },
        "extendedSentence": [
            0,
            92
        ],
        "name": "MedicationMention",
        "sectionName": "Medications",
        "sectionOffset": [
            0,
            92
        ],
        "sectionOid": "2.16.840.1.113883.3.88.11.62.1.1",
        "sentence": [
            0,
            92
        ],
        "text": [
            "chlorhexidine gluconate 0.12 % mouthwash RINSE AND SPIT- TWICE A DAY W/10 ML FOR PLAQUE CONTROL OR SWAB GUMLINES NIGHTLY AND DONT WASH AWAY RESIDUE",
            0,
            92
        ],
        "umlsConcept": [
            {
                "code": "313665",
                "codingScheme": "RxNorm",
                "cui": "C152144",
                "preferredText": "chlorhexidine gluconate 0.12 % mouthwash",
                "tui": "T195"
            }
        ]
    },
    {
        "attributes": {
            "FamilyMember": "",
            "confidence": "1",
            "date": "",
            "derivedGeneric": "1",
            "fasting": "False",
            "labUnit": "",
            "labValue": "",
            "medDosage": "50 mcg",
            "medForm": "capsule",
            "medFrequencyNumber": "1",
            "medFrequencyUnit": "day",
            "medRoute": "oral",
            "medStrengthNum": "2,000",
            "medStrengthUnit": "unit",
            "polarity": "positive",
            "relTime": "current status",
            "secondary": "False",
            "status": "",
            "umlsConcept": [
                {
                    "code": "197360",
                    "codingScheme": "RxNorm",
                    "cui": "C1514591",
                    "preferredText": "Vitamin D3 50 mcg (2000 unit) oral capsule",
                    "tui": "T195"
                }
            ]
        },
        "extendedSentence": [
            0,
            92
        ],
        "name": "MedicationMention",
        "sectionName": [
            "Medications",
            0,
            92
        ],
        "sectionOffset": [
            0,
            92
        ],
        "sectionOid": "2.16.840.1.113883.3.88.11.62.1.1",
        "sentence": [
            0,
            92
        ],
        "text": [
            "Vitamin_D3 Vitamin D3 50 mcg (2,000 unit) capsule Take 1 capsule every day by oral route as directed for 28 days.",
            0,
            92
        ]
    },
    {
        "attributes": {
            "FamilyMember": "",
            "confidence": "1",
            "date": "MM-DD-YYYY",
            "derivedGeneric": "1",
            "fasting": "False",
            "labUnit": "",
            "labValue": "",
            "medDosage": "1 patch",
            "medForm": "topical patch",
            "medFrequencyNumber": "4",
            "medFrequencyUnit": "times a day",
            "medRoute": "topical route",
            "medStrengthNum": "3.1",
            "medStrengthUnit": "%",
            "polarity": "positive",
            "relTime": "current status",
            "secondary": "False",
            "status": "stable",
            "umlsConcept": [
                {
                    "code": "197366",
                    "codingScheme": "RxNorm",
                    "cui": "C152144",
                    "preferredText": "Salonpas Patch",
                    "tui": "T121"
                }
            ]
        },
        "extendedSentence": [
            0,
            100
        ],
        "name": "MedicationMention",
        "sectionName": "Medications",
        "sectionOffset": [
            0,
            100
        ],
        "sectionOid": "2.16.840.1.113883.3.88.11.62.1",
        "sentence": [
            0,
            100
        ],
        "text": [
            "Salonpas Salonpas 3.1 %-10 %-6 % topical patch Apply 1 patch 4 times a day by topical route as needed.",
            0,
            100
        ]
    }
]